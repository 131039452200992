import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M2.6463 12.4552C2.6463 17.9871 7.13098 22.4718 12.6628 22.4718C18.1947 22.4718 22.6794 17.9871 22.6794 12.4552C22.6794 6.92334 18.1947 2.43866 12.6628 2.43866C7.13098 2.43866 2.6463 6.92334 2.6463 12.4552ZM4.46749 12.4552C4.46749 7.92909 8.13674 4.25985 12.6628 4.25985C17.189 4.25985 20.8582 7.92909 20.8582 12.4552C20.8582 16.9813 17.189 20.6506 12.6628 20.6506C8.13674 20.6506 4.46749 16.9813 4.46749 12.4552ZM6.72258 15.0641L6.76811 15.1155L9.87688 18.3513C9.95879 18.4365 10.0706 18.4865 10.1887 18.4907C10.3068 18.495 10.4219 18.4532 10.5097 18.3741L10.5498 18.3331L11.0757 17.7235C11.1431 17.6452 11.182 17.5463 11.1858 17.443C11.1896 17.3398 11.1581 17.2383 11.0966 17.1552L11.0593 17.1106L9.38288 15.3659H18.3541C18.4657 15.3659 18.5733 15.3249 18.6567 15.2507C18.74 15.1765 18.7932 15.0743 18.8062 14.9634L18.8094 14.9106V14.0487C18.8094 13.9372 18.7684 13.8296 18.6943 13.7463C18.6202 13.6629 18.5181 13.6097 18.4073 13.5966L18.3541 13.5935H7.34452C6.61695 13.5935 6.26273 14.5086 6.72258 15.0641ZM6.51633 10.8617C6.51633 10.9215 6.52811 10.9807 6.55099 11.0359C6.57387 11.0911 6.60741 11.1413 6.64968 11.1836C6.69196 11.2259 6.74215 11.2594 6.79739 11.2823C6.85263 11.3052 6.91184 11.317 6.97163 11.317H17.9812C18.732 11.317 19.0848 10.3435 18.5576 9.7949L15.4493 6.5591C15.4342 6.54362 15.4342 6.54362 15.4183 6.52951C15.3268 6.45074 15.2078 6.41153 15.0874 6.4205C14.967 6.42946 14.8551 6.48587 14.7763 6.57732L14.25 7.18696C14.176 7.27286 14.1367 7.38329 14.1398 7.49663C14.143 7.60998 14.1882 7.71808 14.2669 7.79979L15.9428 9.54449H6.97163C6.91184 9.54449 6.85263 9.55627 6.79739 9.57915C6.74215 9.60203 6.69196 9.63557 6.64968 9.67784C6.60741 9.72012 6.57387 9.77031 6.55099 9.82555C6.52811 9.88079 6.51633 9.94 6.51633 9.99979V10.8617Z" />
    </Svg>
  )
}

export default Icon
