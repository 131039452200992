import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 30 30" {...props}>
      <path d="M25.9141 9.03448C25.1596 9.36846 24.3503 9.59494 23.4995 9.69629C24.3678 9.17708 25.0346 8.35143 25.3473 7.37066C24.5354 7.85223 23.6347 8.20129 22.6764 8.39141C21.9095 7.57449 20.8174 7.06281 19.6075 7.06281C17.2844 7.06281 15.4016 8.94571 15.4016 11.2676C15.4016 11.5966 15.4391 11.9182 15.5105 12.2259C12.0164 12.0495 8.91746 10.3756 6.8433 7.83091C6.48051 8.45276 6.27398 9.17579 6.27398 9.94519C6.27398 11.4039 6.92514 12.6913 8.05359 13.4456C7.36425 13.4232 6.05753 13.2342 6.05753 12.9189C6.05753 12.9365 6.05753 12.9527 6.05753 12.9714C6.05753 15.0082 7.59944 16.7083 9.52235 17.0949C9.16954 17.1913 8.8434 17.2425 8.45932 17.2425C8.18774 17.2425 7.94782 17.215 7.69008 17.1662C8.22558 18.8376 9.7894 20.0524 11.6297 20.0874C10.191 21.2146 8.38259 21.8876 6.41233 21.8876C6.07194 21.8876 5.74083 21.8664 5.41174 21.8289C7.27336 23.0211 9.48407 23.7179 11.8598 23.7179C19.595 23.7179 23.8254 17.3101 23.8254 11.7531C23.8254 11.5704 23.822 11.389 23.8133 11.2088C24.6353 10.6159 25.3472 9.87512 25.9114 9.03196L25.9141 9.03448Z" />
    </Svg>
  )
}

export default Icon
