import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 30 30" {...props}>
      <path d="M24.3837 8.84827C23.7507 8.13906 22.941 7.61026 22.0371 7.31573C21.1333 7.0212 20.1675 6.97145 19.2382 7.17154C18.7909 7.28216 18.5665 7.67409 18.6218 8.12132C18.734 8.57013 19.126 8.79296 19.5732 8.73765C20.8596 8.45793 22.3151 8.84827 23.2648 9.91183C24.2162 10.9754 24.4975 12.4293 24.0487 13.7157C24.0161 13.8169 24.0041 13.9236 24.0136 14.0295C24.0231 14.1354 24.0539 14.2382 24.104 14.332C24.2162 14.4995 24.3837 14.667 24.5528 14.7223C24.7529 14.7715 24.964 14.748 25.1484 14.6559C25.3328 14.5639 25.4785 14.4093 25.5595 14.2198C26.062 12.374 25.727 10.3591 24.3837 8.84827Z" />
      <path d="M21.9784 13.7707C22.315 13.8829 22.7054 13.7154 22.8176 13.3234C23.0973 12.429 22.9298 11.4776 22.2581 10.7507C21.9449 10.4077 21.5492 10.1505 21.1086 10.0037C20.6679 9.85679 20.197 9.82507 19.7407 9.91152C19.6528 9.92322 19.5686 9.95393 19.4938 10.0015C19.4191 10.0491 19.3555 10.1123 19.3077 10.1869C19.2599 10.2616 19.2289 10.3457 19.2169 10.4335C19.2049 10.5213 19.2121 10.6107 19.2381 10.6954C19.2935 11.0857 19.6854 11.3101 20.0204 11.1979C20.4692 11.0873 20.9164 11.2548 21.2531 11.5898C21.5881 11.9249 21.6434 12.4274 21.5312 12.8762C21.4206 13.2681 21.6418 13.6601 21.9784 13.7707ZM20.6352 15.0586C20.357 14.9464 20.1326 14.8896 20.3001 14.5545C20.6367 13.717 20.6367 12.9884 20.3001 12.4843C19.6301 11.5345 17.7842 11.5898 15.714 12.4843C15.714 12.4843 15.0424 12.764 15.2099 12.2615C15.5465 11.1979 15.4896 10.3588 14.9871 9.85621C13.8682 8.73734 10.8466 9.91152 8.27384 12.4843C6.37112 14.4455 5.25067 16.4572 5.25067 18.2462C5.25067 21.6597 9.61395 23.7299 13.8666 23.7299C19.461 23.7299 23.1526 20.4839 23.1526 17.9111C23.1526 16.345 21.8093 15.449 20.6352 15.0586ZM13.8682 22.4972C10.4547 22.8339 7.54689 21.2678 7.32248 19.0853C7.09965 16.8476 9.67243 14.7789 13.0844 14.4423C16.4979 14.1073 19.4057 15.6734 19.6301 17.8558C19.8529 20.092 17.2801 22.1622 13.8682 22.4972Z" />
      <path d="M14.2031 16.1207C12.5816 15.6735 10.7342 16.5127 10.0626 17.9113C9.33565 19.3652 10.0626 20.9882 11.6856 21.4907C13.3639 22.0501 15.3772 21.211 16.0489 19.6449C16.7205 18.1341 15.8814 16.568 14.2031 16.1207ZM12.972 19.8693C12.6369 20.3718 11.9653 20.6515 11.4075 20.3718C10.9017 20.149 10.7342 19.5343 11.0708 19.0285C11.4059 18.526 12.0775 18.3016 12.5816 18.526C13.1411 18.6935 13.3086 19.3098 12.972 19.8693Z" />
    </Svg>
  )
}

export default Icon
