import { ethers } from 'ethers'
import getRpcUrl from 'utils/getRpcUrl'

const RPC_URL = getRpcUrl()
const BSC_RPC_URL = getRpcUrl(true)

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL)

export const bscRpcProvider = new ethers.providers.JsonRpcProvider(BSC_RPC_URL)

export default null
