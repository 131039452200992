import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10.538 2.98653V5.58922H9.5106V2.98653C9.5106 2.8016 9.34622 2.71256 9.23663 2.71256C9.20238 2.71256 9.16814 2.71941 9.13389 2.73311L3.70248 4.78102C3.33947 4.918 3.1066 5.26046 3.1066 5.65086V6.10976C2.48333 6.5755 2.07922 7.32207 2.07922 8.16452V5.65086C2.07922 4.83581 2.57921 4.10979 3.33948 3.82213L8.77773 1.76737C8.92842 1.71258 9.08595 1.68518 9.23663 1.68518C9.92155 1.68518 10.538 2.23997 10.538 2.98653Z"
        fill="#F8FBFF"
      />
      <path
        d="M15.0927 10.2124V10.8973C15.0927 11.0822 14.9488 11.2329 14.7571 11.2398H13.7571C13.3941 11.2398 13.0653 10.9727 13.0379 10.6165C13.0174 10.4042 13.0996 10.2055 13.2365 10.0686C13.3598 9.93843 13.5311 9.86993 13.716 9.86993H14.7502C14.9488 9.87678 15.0927 10.0275 15.0927 10.2124Z"
        fill="#F8FBFF"
      />
      <path
        d="M13.7092 9.15075H14.4078C14.7845 9.15075 15.0927 8.84254 15.0927 8.46583V8.16447C15.0927 6.74669 13.9352 5.58917 12.5174 5.58917H4.65452C4.07234 5.58917 3.5381 5.78095 3.1066 6.10971C2.48333 6.57546 2.07922 7.32202 2.07922 8.16447V12.774C2.07922 14.1918 3.23674 15.3493 4.65452 15.3493H12.5174C13.9352 15.3493 15.0927 14.1918 15.0927 12.774V12.6438C15.0927 12.2671 14.7845 11.9589 14.4078 11.9589H13.8119C13.1544 11.9589 12.5242 11.5548 12.353 10.9178C12.2092 10.3973 12.3804 9.89731 12.7229 9.5617C12.9763 9.30143 13.3256 9.15075 13.7092 9.15075ZM9.95579 9.01377H5.16136C4.88054 9.01377 4.64767 8.7809 4.64767 8.50008C4.64767 8.21926 4.88054 7.98639 5.16136 7.98639H9.95579C10.2366 7.98639 10.4695 8.21926 10.4695 8.50008C10.4695 8.7809 10.2366 9.01377 9.95579 9.01377Z"
        fill="#F8FBFF"
      />
    </Svg>
  )
}

export default Icon
