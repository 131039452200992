import { Farm } from 'state/types'
import fetchPublicFarmData from './fetchPublicFarmData'
import fetchPublicFarmDataBSC from './fetchPublicFarmDataBSC'

const fetchFarm = async (farm: Farm): Promise<Farm> => {
  const farmPublicData = !farm.bridgeable ? await fetchPublicFarmData(farm) : await fetchPublicFarmDataBSC(farm)

  return { ...farm, ...farmPublicData }
}

export default fetchFarm
