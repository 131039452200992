export default {
  masterChef: {
    1280: '0x9A37CB6A5Ec8508018e76a593eB4eAec17E1a8f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    1280: '0xe59E7980135a6643822A5C2Bc18FB71124F78bCF',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  multiCall: {
    1280: '0xece32251731D91ec737131E875c4b7662de544f8',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  },
  pancakeProfile: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  pancakeRabbits: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  bunnyFactory: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  claimRefund: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  pointCenterIfo: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecial: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  tradingCompetition: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  easterNft: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  cakeVault: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  predictions: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  chainlinkOracle: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecialCakeVault: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecialPrediction: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecialLottery: {
    1280: '0x000000000000000000000000000000000000dead',
  },
  farmAuction: {
    1280: '0x000000000000000000000000000000000000dead',
  },
}
