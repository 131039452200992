import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 30 30" {...props}>
      <path d="M24.0182 6.68591C24.1893 6.88085 24.2434 7.17327 24.1623 7.43645L19.3702 23.8411C19.3072 24.0458 19.1721 24.2018 19.0099 24.27C18.9198 24.309 18.8117 24.3188 18.7127 24.2993C18.6406 24.2798 18.5685 24.2505 18.5055 24.192L14.6952 21.2776L12.02 24.0556C11.8939 24.2018 11.7137 24.2603 11.5336 24.2115C11.4615 24.2018 11.3984 24.1726 11.3354 24.1238C11.1192 23.9679 11.0111 23.6754 11.0471 23.383L11.6056 19.5426L21.1537 9.57111L9.96623 17.6711L6.6424 15.1271C6.43523 14.9906 6.32714 14.7177 6.37217 14.4448C6.39019 14.1718 6.54332 13.9379 6.7595 13.8599L23.4056 6.5202C23.5047 6.47147 23.6038 6.46172 23.7119 6.48122C23.82 6.51046 23.9371 6.57869 24.0182 6.68591Z" />
    </Svg>
  )
}

export default Icon
