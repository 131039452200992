import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'HO/USDT LPToken',
    lpAddresses: {
      1280: '0x6753C81d76B2Ce387FdC0Aa36e286ec8Be034E2b',
    },
    token: tokens.who,
    quoteToken: tokens.usdt,
    earningToken: tokens.hos,
  },
  {
    pid: 1,
    lpSymbol: 'HOS/HO LPToken',
    lpAddresses: {
      1280: '0x8E282Fc58d281c43914f72f61aD22D729A7D569F',
    },
    token: tokens.hos,
    quoteToken: tokens.who,
    earningToken: tokens.hos,
  },
  // pid = 2 在配置时写错了stackingToken地址，导致无法启动，所以PID=2为一个无效的废弃农场
  {
    pid: 3,
    lpSymbol: 'HO/USDT LPToken In BSC',
    lpAddresses: {
      1280: '0xd7Ec15dfC5899394645a49Ff8feC1FAdd17eF4fc',
      56: '0xc1b5ce0a8c25e8aba618f96f08d0b6c871b32a59',
    },
    bridgeable: true,
    token: tokens.who,
    quoteToken: tokens.usdt,
    earningToken: tokens.hos,
  },
  {
    pid: 4,
    lpSymbol: 'HO/USDT LPToken',
    lpAddresses: {
      1280: '0x6753C81d76B2Ce387FdC0Aa36e286ec8Be034E2b',
    },
    token: tokens.who,
    quoteToken: tokens.usdt,
    earningToken: tokens.hos,
  },
  {
    pid: 5,
    lpSymbol: 'HOS/HO LPToken',
    lpAddresses: {
      1280: '0x8E282Fc58d281c43914f72f61aD22D729A7D569F',
    },
    token: tokens.hos,
    quoteToken: tokens.who,
    earningToken: tokens.hos,
  }
]

export default farms
