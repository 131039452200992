import { ChainId, Token } from '@hoswap/sdk'

export const USDT: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x41515885251e724233c6cA94530d6dcf3A20dEc7',
    18,
    'USDT',
    'Halo-Peg USDT',
  ),
}

export const HOS: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x6b61e560daE09b2488e8fc597eABAfeafC3A5EF1',
    18,
    'HOS',
    'HOSWAP Governance Token',
  ),
}

export const WHO: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x41b474F1E7413d472CA93dFd01eE4Ea0a7245430', 18, 'WHO', 'WHO'),
}

const tokens = {
  bnb: {
    symbol: 'HO',
    projectLink: 'https://halo.land',
  },
  who: {
    symbol: 'WHO',
    address: {
      1280: '0x41b474F1E7413d472CA93dFd01eE4Ea0a7245430',
      56: '0x41515885251e724233c6ca94530d6dcf3a20dec7',
    },
    decimals: 18,
  },
  usdt: {
    symbol: 'USDT',
    address: {
      1280: '0x41515885251e724233c6cA94530d6dcf3A20dEc7',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    decimals: 18,
  },
  hos: {
    symbol: 'HOS',
    address: {
      1280: '0x6b61e560daE09b2488e8fc597eABAfeafC3A5EF1',
    },
    decimals: 18,
  },
}

export default tokens
