import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M22.4533 7.97755L18.0649 3.58916C17.6359 3.16016 17.075 2.94659 16.5142 2.94659C15.9533 2.94659 15.3906 3.16016 14.9616 3.59102C14.5345 4.01816 14.3191 4.58087 14.3191 5.14172C14.3191 5.70257 14.5326 6.26528 14.9635 6.69242L15.606 7.33498L12.3654 10.5757L9.13395 7.34427L4.07886 12.3994V19.3302C4.07886 20.0544 4.376 20.7137 4.85142 21.191C5.3287 21.6683 5.98798 21.9636 6.71226 21.9636H13.6431L18.6982 16.9085L15.4668 13.6789L18.7074 10.4382L19.35 11.0808C19.7771 11.5079 20.3399 11.7234 20.9007 11.7234C21.4616 11.7234 22.0243 11.5098 22.4533 11.0808C22.8804 10.6537 23.0958 10.091 23.0958 9.53011C23.0958 8.9674 22.8823 8.40655 22.4533 7.97755ZM16.6293 16.9085L13.0376 20.5002H6.71226C6.39098 20.5002 6.09755 20.3683 5.88584 20.1566C5.67413 19.9449 5.54227 19.6514 5.54227 19.3302V13.0048L9.13395 9.41311L11.3309 11.6101L9.10981 13.8294C8.68267 14.2565 8.46725 14.8192 8.46725 15.3801C8.46725 15.9409 8.68082 16.5036 9.11167 16.9326C9.53881 17.3598 10.1015 17.5752 10.6624 17.5752C11.2232 17.5752 11.7859 17.3616 12.2131 16.9326L14.4323 14.7133L16.6293 16.9085ZM21.4188 10.0464C21.2777 10.1875 21.0901 10.26 20.9026 10.26C20.715 10.26 20.5274 10.1894 20.3863 10.0464L18.7074 8.3694L11.1786 15.8982C11.0375 16.0393 10.8499 16.1118 10.6624 16.1118C10.4748 16.1118 10.2872 16.0412 10.1442 15.8963C10.0031 15.7552 9.93066 15.5676 9.93066 15.3801C9.93066 15.1925 10.0012 15.0049 10.1442 14.8638L17.6712 7.33684L15.996 5.658C15.8549 5.51686 15.7825 5.32929 15.7825 5.14172C15.7825 4.95415 15.853 4.76658 15.9979 4.62358C16.139 4.48244 16.3266 4.41001 16.5142 4.41001C16.7017 4.41001 16.8893 4.48058 17.0305 4.62358L21.4188 9.01197C21.5618 9.15497 21.6324 9.34254 21.6324 9.52825C21.6324 9.71582 21.5618 9.90339 21.4188 10.0464Z"
        stroke="#5C5C75"
        strokeWidth="0.4"
      />
    </Svg>
  )
}

export default Icon
